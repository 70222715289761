<template>
  <div class="base-resources">
    Base resources needed: 
    <div class="badge bg-light text-dark mr-2" v-for="resource of baseResources" :key="resource.name">
      {{ resource.amount }}x {{ resource.name }} 
        (<span v-for="(source, index) of resource.sources" :key="source">{{ source }}<span v-if="index !== resource.sources.length - 1">, </span>
        </span>)
    </div>
  </div>
</template>

<script>
import base from '../api/base'

export default {
  props: {
    selectedObject: Object,
  },

  computed: {
    baseResources() {
      const allResources = this.determineBaseResources(this.selectedObject)
      const uniqueResources = allResources.filter((value, index, self) => 
        index === self.findIndex(t => t.name === value.name))
      const duplicateResources = allResources.filter((value, index, self) =>
        index !== self.findIndex(t => t.name === value.name))
      
      for (const resource of uniqueResources) {
        var dupIdx = duplicateResources.findIndex(e => e.name === resource.name)
        if (dupIdx >= 0) {
          resource.amount += duplicateResources[dupIdx].amount
        }

        if (base.allPlanets.every(p => resource.sources.includes(p))) {
          resource.sources = resource.sources.filter(e => base.allPlanets.indexOf(e) === -1)
          resource.sources.push("All Planets")
        }
      }

      return uniqueResources
    },
  },

  methods: {
    // todo this is not good. every push forces a new update
    determineBaseResources( object, amount = 1 ) {
      if (object.resources) {
        let baseResources = []
        for (const resource of object.resources) {
          const newAmount = amount * resource.amount
          const res = this.determineBaseResources(resource.res, newAmount)
          baseResources = baseResources.concat(res)
        }
        return baseResources
      } else if (object.sources) {
        return [{ name: object.name, amount: amount, sources: [ ...object.sources ] }]
      } else {
        return []
      }
    },


  }
}
</script>

<style lang="scss" scoped>
.base-resources {

}
</style>