"use strict";
// cudos to https://observablehq.com/@sgregson/astroneer-resource-planner
import base from './base'

// smelting
const glass = { name: "Glass",  ...base.smelting, resources: [{ res: base.quartz, amount: 1 }]}
const carbon = { name: "Carbon",  ...base.smelting, resources: [{ res: base.organic, amount: 1 }] }
const tungsten = { name: "Tungsten",  ...base.smelting, resources: [{ res: base.wolframite, amount: 1 }] }
const ceramic = { name: "Ceramic",  ...base.smelting, resources: [{ res: base.clay, amount: 1 }] }
const iron = { name: "Iron",  ...base.smelting, resources: [{ res: base.hematite, amount: 1 }] }
const copper = { name: "Copper",  ...base.smelting, resources: [{ res: base.malachite, amount: 1 }] }
const aluminum = { name: "Aluminum",  ...base.smelting, resources: [{ res: base.laterite, amount: 1 }] }
const titanium = { name: "Titanium",  ...base.smelting, resources: [{ res: base.titanite, amount: 1 }] }
const zinc = { name: "Zinc",  ...base.smelting, resources: [{ res: base.sphalerite, amount: 1 }] }

// chem lab
const silicone = { name: "Silicone",  ...base.lab, resources: [{ res: base.resin, amount: 1 },{ res: base.quartz, amount: 1 },{ res: base.methane, amount: 1 }] }
const rubber = { name: "Rubber",  ...base.lab, resources: [{ res: base.resin, amount: 1 },{ res: base.organic, amount: 1 }] }
const plastic = { name: "Plastic",  ...base.lab, resources: [{ res: base.compound, amount: 1 },{ res: carbon, amount: 1 }] }
const explosive_powder = { name: "Explosive Powder",  ...base.lab, resources: [{ res: base.sulfur, amount: 1 },{ res: carbon, amount: 1 }] }
const steel = { name: "Steel",  ...base.lab, resources: [{ res: carbon, amount: 1 },{ res: base.argon, amount: 1 },{ res: iron, amount: 1 }] }
const tungsten_carbide = { name: "Tungsten Carbide",  ...base.lab, resources: [{ res: tungsten, amount: 1 },{ res: carbon, amount: 1 }] }
const aluminum_alloy = { name: "Aluminum Alloy",  ...base.lab, resources: [{ res: copper, amount: 1 },{ res: aluminum, amount: 1 }] }
const hydrazine = { name: "Hydrazine",  ...base.lab, resources: [{ res: base.ammonium, amount: 2 },{ res: base.hydrogen, amount: 1 }] }
const graphene = { name: "Graphene",  ...base.lab, resources: [{ res: hydrazine, amount: 1 },{ res: base.graphite, amount: 1 }] }
const diamond = { name: "Diamond",  ...base.lab, resources: [{ res: graphene, amount: 2 }] }
const titanium_alloy = { name: "Titanium Alloy",  ...base.lab, resources: [{ res: graphene, amount: 1 },{ res: titanium, amount: 1 }] }
const nanocarbon_alloy = { name: "Nanocarbon Alloy", ...base.lab, resources: [{ res: steel, amount: 1 },{ res: titanium_alloy, amount: 1 },{ res: base.helium, amount: 1 }] }

// other
const small_canister = { name: "Small Canister", sources: [ ...base.allPlanets ]}
const exo_chip = { name: "EXO Chip", sources: [...base.trade, "Wrecks"]}

export default {

  // smelting
  glass,
  carbon,
  tungsten,
  ceramic,
  iron,
  copper,
  aluminum,
  titanium,
  zinc,

  // chem lab
  silicone,
  rubber,
  plastic,
  explosive_powder,
  steel,
  tungsten_carbide,
  aluminum_alloy,
  hydrazine,
  graphene,
  diamond,
  titanium_alloy,
  nanocarbon_alloy,

  // other
  small_canister,
  exo_chip,
}