<template>
  <div>Flights needed: 
    <div class="badge bg-light text-dark mr-2" v-for="source of optimalSources" :key="source">
      {{ source }}
    </div>
  </div> 
</template>

<script>
export default {
  
  props: {
    selectedObject: Object,
  },

  computed: {
    sourcesOfSources() {
      return this.determineSources(this.selectedObject)
    },
    allSources() {
      if (this.sourcesOfSources.length) {
        return this.sourcesOfSources.reduce((a1, a2) => a1.filter(e => a2.includes(e)))
      } else {
        return []
      }
    },
    optimalSources() {
      return this.determineOptimalSources(this.sourcesOfSources)
    }
  },

  methods: {
    // todo this is not good. every push forces a new update
    determineSources( object ) {
      if (object.resources) {
        let allSources = []
        for (const resource of object.resources) {
          const src = this.determineSources(resource.res)
          if (src.length && Array.isArray(src[0])) {
            allSources = allSources.concat(src)
          } else {
            allSources.push(src)
          }
          
        }
        return allSources
      } else if (object.sources) {
        return object.sources
      } else {
        return []
      }
    },

    determineOptimalSources( allSources ) {
      let optimalSources = []
      outer: for (let i=0; i<allSources.length; i++) {
        const sources = allSources[i]
        let foundSomething = false
        
        for (const source of sources) {
          const foundOtherSources = allSources.filter((a1, index) => 
            index !== i && a1.indexOf(source) > -1)
          if (foundOtherSources.length) {
            foundSomething = true
            if (optimalSources.indexOf(source) === -1) {
              optimalSources.push(source)
              console.log(source + "_" + i)
            }
            continue outer
          }
        }

        if (!foundSomething) { // TODO: maybe it is more useful to weight the sources
          optimalSources.push(sources[0])
          
        }
      }
      return optimalSources
    }
  }
}
</script>

<style lang="scss" scoped>

</style>