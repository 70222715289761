<template>
  <div>
    <v-select 
      name="search"
      class="search-bar mb-3" 
      v-model="selectedEntity"
      :options="availableEntities"
      :reduce="entity => entity.code"
      @option:selected="$emit('searched', this.selectedEntity)"
    ></v-select>
  </div>  
</template>

<script>
export default {

  emits: [ 'searched' ],

  props: {
    objects: Object,
  },
  
  data() {
    return {
      selectedEntity: '',
      availableEntities: this.convertObjects(this.objects),
    }
  },

  methods: {
    convertObjects( objects ) {
      return Object.entries(objects).map(([key, value]) => {
        return {
          code: key,
          label: value.name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>


</style>