"use strict";
// import test from './testObjects'

// export default {
//   ...test
// }

import resources from './resources'
import base from './base'

// taken from here https://astroneer.fandom.com/wiki/Items

export default {

  // small objects - tier 1
  small_printer: {
    name: "Small Printer",
    tier: "small",
    resources: [
      { res: base.compound, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 'unlocked',
  },
  packager: {
    name: "Packager",
    tier: "small",
    resources: [
      { res: base.graphite, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 1000,
  },
  leveling_block: {
    name: "Leveling Block",
    tier: "small",
    resources: [
      { res: resources.small_canister, amount: 100 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 500,
  },
  tethers: {
    name: "Tethers",
    tier: "small",
    resources: [
      { res:base.compound, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 'unlocked',
  },
  oxygen_filters: {
    name: "Oxygen Filters",
    tier: "small",
    resources: [
      { res:base.resin, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 'unlocked',
  },
  oxygen_tank: {
    name: "Oxygen Tank",
    tier: "small",
    resources: [
      { res:resources.glass, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 2000,
  },
  portable_oxygenator: {
    name: "Portable Oxygenator",
    tier: "small",
    resources: [
      { res:resources.nanocarbon_alloy, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 10000,
  },
  small_canister: {
    name: "Small Canister",
    tier: "small",
    resources: [
      { res:base.resin, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 'unlocked',
  },
  beacon: {
    name: "Beacon",
    tier: "small",
    resources: [
      { res:base.quartz, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 'unlocked',
  },
  worklight: {
    name: "Worklight",
    tier: "small",
    resources: [
      { res:resources.copper, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 'unlocked',
  },
  glowsticks: {
    name: "Glowsticks",
    tier: "small",
    resources: [
      { res:base.organic, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 350,
  },
  floodlight: {
    name: "Flooedlight",
    tier: "small",
    resources: [
      { res:resources.tungsten, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 2000,
  },
  small_generator: {
    name: "Small Generator",
    tier: "small",
    resources: [
      { res:base.compound, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 'unlocked',
  },
  power_cells: {
    name: "Power Cells",
    tier: "small",
    resources: [
      { res:base.graphite, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 800,
  },
  small_solar_panel: {
    name: "Small Solar Panel",
    tier: "small",
    resources: [
      { res:resources.copper, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 300,
  },
  small_wind_turbine: {
    name: "Small Wind Turbine",
    tier: "small",
    resources: [
      { res:resources.ceramic, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 300,
  },
  small_battery: {
    name: "Small Battery",
    tier: "small",
    resources: [
      { res:resources.zinc, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 2000,
  },
  boost_mod: {
    name: "Boost Mod",
    tier: "small",
    resources: [
      { res:resources.zinc, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 1000,
  },
  wide_mod: {
    name: "Wide Mod",
    tier: "small",
    resources: [
      { res:resources.zinc, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 1000,
  },
  narrow_mod: {
    name: "Narrow Mod",
    tier: "small",
    resources: [
      { res:resources.ceramic, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 1000,
  },
  inhibitor_mod: {
    name: "Inhibitor Mod",
    tier: "small",
    resources: [
      { res:resources.zinc, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 1000,
  },
  alignment_mod: {
    name: "Alignment Mod",
    tier: "small",
    resources: [
      { res:resources.zinc, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 1000,
  },
  drill_mod_1: {
    name: "Drill Mod 1",
    tier: "small",
    resources: [
      { res:resources.ceramic, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 1000,
  },
  drill_mod_2: {
    name: "Drill Mod 2",
    tier: "small",
    resources: [
      { res:resources.tungsten_carbide, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 2500,
  },
  drill_mod_3: {
    name: "Drill Mod 3",
    tier: "small",
    resources: [
      { res:resources.diamond, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 3750,
  },
  dynamite: {
    name: "Dynamite",
    tier: "small",
    resources: [
      { res:resources.explosive_powder, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 3750,
  },
  fireworks: {
    name: "Fireworks",
    tier: "small",
    resources: [
      { res:resources.explosive_powder, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 3750,
  },
  small_camera: {
    name: "Small Camera",
    tier: "small",
    resources: [
      { res:resources.exo_chip, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 2500,
  },
  small_trumpet_horn: {
    name: "Small Trumpet Horn",
    tier: "small",
    resources: [
      { res:resources.plastic, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 1000,
  },
  holographic_figurine: {
    name: "Holographic Figurine",
    tier: "small",
    resources: [
      { res:resources.plastic, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 3000,
  },
  terrain_analyzer: {
    name: "Terrain Analyzer",
    tier: "small",
    resources: [
      { res:resources.zinc, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 2000,
  },
  probe_scanner: {
    name: "Probe Scanner",
    tier: "small",
    resources: [
      { res:resources.steel, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 4000,
  },
  solid_fuel_jump_jet: {
    name: "Solid-Fuel Jump Jet",
    tier: "small",
    resources: [
      { res:resources.aluminum_alloy, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 3750,
  },
  hydrazine_jet_pack: {
    name: "Hydrazine Jet Pack",
    tier: "small",
    resources: [
      { res:resources.titanium_alloy, amount: 1 },
    ],
    built_in: "Backpack Printer",
    byte_cost: 15000,
  },

  // medium objects - tier 2
  medium_printer: {
    name: "Medium Printer",
    tier: "medium",
    resources: [
      { res: base.compound, amount: 2 },
    ],
    built_in: "Small Printer",
    byte_cost: "unlocked",
  },
  oxygenator: {
    name: "Oxygenator",
    tier: "medium",
    resources: [
      { res: resources.aluminum, amount: 1 },
      { res: resources.ceramic, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 1800,
  },
  medium_shredder: {
    name: "Medium Shredder",
    tier: "medium",
    resources: [
      { res: resources.iron, amount: 2 },
    ],
    built_in: "Small Printer",
    byte_cost: 1250,
  },
  field_shelter: {
    name: "Field Shelter",
    tier: "medium",
    resources: [
      { res: resources.silicone, amount: 1 },
      { res: resources.graphene, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 8000,
  },
  auto_arm: {
    name: "Auto Arm",
    tier: "medium",
    resources: [
      { res: resources.aluminum, amount: 1 },
      { res: resources.graphite, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 1500,
  },
  medium_resource_canister: {
    name: "Medium Resource Canister",
    tier: "medium",
    resources: [
      { res: resources.plastic, amount: 1 },
      { res: resources.glass, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 2000,
  },
  medium_fluid_soil_canister: {
    name: "Medium Fluid & Soil Canister",
    tier: "medium",
    resources: [
      { res: resources.plastic, amount: 1 },
      { res: resources.glass, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 2500,
  },
  medium_gas_canister: {
    name: "Medium Gas Canister",
    tier: "medium",
    resources: [
      { res: resources.silicone, amount: 1 },
      { res: resources.glass, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 4000,
  },
  power_sensor: {
    name: "Power Sensor",
    tier: "medium",
    resources: [
      { res: resources.zinc, amount: 1 },
      { res: base.quartz, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 500,
  },
  storage_sensor: {
    name: "Storage Sensor",
    tier: "medium",
    resources: [
      { res: resources.zinc, amount: 1 },
      { res: base.quartz, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 750,
  },
  battery_sensor: {
    name: "Battery Sensor",
    tier: "medium",
    resources: [
      { res: resources.zinc, amount: 1 },
      { res: base.graphite, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 750,
  },
  button_repeater: {
    name: "Button Repeater",
    tier: "medium",
    resources: [
      { res: resources.zinc, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 300,
  },
  delay_repeater: {
    name: "Delay Repeater",
    tier: "medium",
    resources: [
      { res: resources.zinc, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 1000,
  },
  count_repeater: {
    name: "Count Repeater",
    tier: "medium",
    resources: [
      { res: resources.zinc, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 1000,
  },
  extenders: {
    name: "Extenders",
    tier: "medium",
    resources: [
      { res: resources.copper, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 500,
  },
  power_switch: {
    name: "Power Switch",
    tier: "medium",
    resources: [
      { res: resources.copper, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 750,
  },
  splitter: {
    name: "Splitter",
    tier: "medium",
    resources: [
      { res: resources.copper, amount: 1 },
      { res: base.graphite, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 1000,
  },
  medium_generator: {
    name: "Medium Generator",
    tier: "medium",
    resources: [
      { res: resources.aluminum, amount: 1 },
      { res: resources.tungsten, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 2000,
  },
  medium_solar_panel: {
    name: "Medium Solar Panel",
    tier: "medium",
    resources: [
      { res: resources.copper, amount: 1 },
      { res: resources.glass, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 2000,
  },
  medium_wind_turbine: {
    name: "Medium Wind Turbine",
    tier: "medium",
    resources: [
      { res: resources.aluminum, amount: 1 },
      { res: resources.ceramic, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 2500,
  },
  medium_battery: {
    name: "Medium Battery",
    tier: "medium",
    resources: [
      { res: base.lithium, amount: 1 },
      { res: resources.zinc, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 3750,
  },
  rtg: {
    name: "RTG",
    tier: "medium",
    resources: [
      { res: resources.nanocarbon_alloy, amount: 1 },
      { res: base.lithium, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 12500,
  },
  medium_platform_a: {
    name: "Medium Platform A",
    tier: "medium",
    resources: [
      { res: base.resin, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: "unlocked",
  },
  medium_platform_b: {
    name: "Medium Platform B",
    tier: "medium",
    resources: [
      { res: base.resin, amount: 2 },
    ],
    built_in: "Small Printer",
    byte_cost: 250,
  },
  medium_platform_c: {
    name: "Medium Platform C",
    tier: "medium",
    resources: [
      { res: base.resin, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 400,
  },
  tall_platform: {
    name: "Tall Platform",
    tier: "medium",
    resources: [
      { res: resources.ceramic, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 750,
  },
  medium_t_platform: {
    name: "Medium T-Platform",
    tier: "medium",
    resources: [
      { res: base.resin, amount: 2 },
    ],
    built_in: "Small Printer",
    byte_cost: 400,
  },
  medium_storage: {
    name: "Medium Storage",
    tier: "medium",
    resources: [
      { res: base.resin, amount: 2 },
    ],
    built_in: "Small Printer",
    byte_cost: "Unlocked",
  },
  medium_storage_silo: {
    name: "Medium Storage Silo",
    tier: "medium",
    resources: [
      { res: resources.titanium, amount: 2 },
    ],
    built_in: "Small Printer",
    byte_cost: 3000,
  },
  tall_storage: {
    name: "Tall Storage",
    tier: "medium",
    resources: [
      { res: resources.ceramic, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 400,
  },
  rover_seat: {
    name: "Rover Seat",
    tier: "medium",
    resources: [
      { res: base.compound, amount: 2 },
    ],
    built_in: "Small Printer",
    byte_cost: "Unlocked",
  },
  tractor: {
    name: "Tractor",
    tier: "medium",
    resources: [
      { res: resources.aluminum, amount: 2 },
    ],
    built_in: "Small Printer",
    byte_cost: 1000,
  },
  trailer: {
    name: "Trailer",
    tier: "medium",
    resources: [
      { res: base.compound, amount: 1 },
      { res: resources.aluminum, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 1500,
  },
  medium_buggy_horn: {
    name: "Medium Buggy Horn",
    tier: "medium",
    resources: [
      { res: resources.plastic, amount: 1 },
      { res: resources.rubber, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 2000,
  },
  winch: {
    name: "Winch",
    tier: "medium",
    resources: [
      { res: resources.exo_chip, amount: 1 },
      { res: resources.rubber, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 3750,
  },
  paver: {
    name: "Paver",
    tier: "medium",
    resources: [
      { res: resources.aluminum_alloy, amount: 1 },
      { res: resources.silicone, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 5000,
  },
  drill_strength_1: {
    name: "Drill Strength 1",
    tier: "medium",
    resources: [
      { res: resources.tungsten_carbide, amount: 1 },
      { res: resources.ceramic, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 2500,
  },
  drill_strength_2: {
    name: "Drill Strength 2",
    tier: "medium",
    resources: [
      { res: resources.titanium_alloy, amount: 1 },
      { res: resources.tungsten_carbide, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 5000,
  },
  drill_strength_3: {
    name: "Drill Strength 3",
    tier: "medium",
    resources: [
      { res: resources.diamond, amount: 1 },
      { res: resources.titanium_alloy, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 7500,
  },
  solid_fuel_thruster: {
    name: "Solid Fuel Thruster",
    tier: "medium",
    resources: [
      { res: resources.aluminum, amount: 1 },
      { res: base.ammonium, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 500,
  },
  hydrazine_thruster: {
    name: "Hydrazine Thruster",
    tier: "medium",
    resources: [
      { res: resources.exo_chip, amount: 1 },
      { res: resources.steel, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 3750,
  },
  rail_posts: {
    name: "Rail Post Bundle",
    tier: "medium",
    resources: [
      { res: base.resin, amount: 1 },
      { res: resources.aluminum, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 750,
    bundle_count: 10,
  },
  tall_rail_posts: {
    name: "Tall Rail Post Bundle",
    tier: "medium",
    resources: [
      { res: base.resin, amount: 1 },
      { res: resources.aluminum, amount: 1 },
    ],
    built_in: "Small Printer",
    byte_cost: 750,
    bundle_count: 10,
  },
  rail_junction: {
    name: "Rail Junction Bundle",
    tier: "medium",
    resources: [
      { res: resources.aluminum, amount: 2 },
    ],
    built_in: "Small Printer",
    byte_cost: 1000,
    bundle_count: 5,
  },


  // large objects - tier 3
  large_printer: {
    name: "Large Printer",
    tier: "large",
    resources: [
      { res: base.compound, amount: 3 },
    ],
    built_in: "Medium Printer",
    byte_cost: "Unlocked",
  },
  smelting_furnance: {
    name: "Smelting Furnance",
    tier: "large",
    resources: [
      { res: base.compound, amount: 1 },
      { res: base.resin, amount: 2 },
    ],
    built_in: "Medium Printer",
    byte_cost: 250,
  },
  soil_centrifuge: {
    name: "Soil Centrifuge",
    tier: "large",
    resources: [
      { res: base.compound, amount: 2 },
      { res: resources.aluminum, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 750,
  },
  chemistry_lab: {
    name: "Chemistry Lab",
    tier: "large",
    resources: [
      { res: resources.glass, amount: 1 },
      { res: resources.ceramic, amount: 1 },
      { res: base.wolframite, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 1600,
  },
  atmospheric_condenser: {
    name: "Atmospheric Condenser",
    tier: "large",
    resources: [
      { res: resources.plastic, amount: 1 },
      { res: resources.glass, amount: 1 },
      { res: resources.iron, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 2200,
  },
  research_chamber: {
    name: "Research Chamber",
    tier: "large",
    resources: [
      { res: base.compound, amount: 2 },
      { res: base.resin, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: "Unlocked",
  },
  exo_request_platform: {
    name: "EXO Request Platform",
    tier: "large",
    resources: [
      { res: base.resin, amount: 2 },
      { res: resources.ceramic, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: "Unlocked",
  },
  trade_platform: {
    name: "Trade Platform",
    tier: "large",
    resources: [
      { res: resources.iron, amount: 1 },
      { res: resources.tungsten, amount: 1 },
      { res: resources.exo_chip, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 2500,
  },
  large_shredder: {
    name: "Large Shredder",
    tier: "large",
    resources: [
      { res: resources.tungsten_carbide, amount: 1 },
      { res: resources.iron, amount: 1 },
      { res: resources.exo_chip, amount: 1},
    ],
    built_in: "Medium Printer",
    byte_cost: 2500,
  },
  large_solar_panel: {
    name: "Large Solar Panel",
    tier: "large",
    resources: [
      { res: resources.aluminum_alloy, amount: 1 },
      { res: resources.glass, amount: 1 },
      { res: resources.copper, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 4000,
  },
  large_wind_turbine: {
    name: "Large Wind Turbine",
    tier: "large",
    resources: [
      { res: resources.aluminum_alloy, amount: 1 },
      { res: resources.glass, amount: 1 },
      { res: resources.ceramic, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 3500,
  },
  large_platform_a: {
    name: "Large Platform A",
    tier: "large",
    resources: [
      { res: base.resin, amount: 2 },
    ],
    built_in: "Medium Printer",
    byte_cost: "Unlocked",
  },
  large_platform_b: {
    name: "Large Platform B",
    tier: "large",
    resources: [
      { res: base.resin, amount: 3 },
    ],
    built_in: "Medium Printer",
    byte_cost: 500,
  },
  large_platform_c: {
    name: "Large Platform C",
    tier: "large",
    resources: [
      { res: base.resin, amount: 1 },
      { res: resources.ceramic, amount: 1 },
      { res: resources.iron, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 1000,
  },
  large_t_platform: {
    name: "Large T-Platform",
    tier: "large",
    resources: [
      { res: resources.aluminum, amount: 2 },
      { res: base.resin, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 1000,
  },
  large_curved_platform: {
    name: "Large Curved Platform",
    tier: "large",
    resources: [
      { res: base.compound, amount: 1 },
      { res: resources.ceramic, amount: 2 },
    ],
    built_in: "Medium Printer",
    byte_cost: 1000,
  },
  large_extended_platform: {
    name: "Large Extended Platform",
    tier: "large",
    resources: [
      { res: base.resin, amount: 2 },
    ],
    built_in: "Medium Printer",
    byte_cost: 500,
  },
  large_resource_canister: {
    name: "Large Resource Canister",
    tier: "large",
    resources: [
      { res: resources.glass, amount: 1 },
      { res: resources.titanium, amount: 1 },
      { res: resources.nanocarbon_alloy, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 5000,
  },
  large_storage: {
    name: "Large Storage",
    tier: "large",
    resources: [
      { res: resources.ceramic, amount: 3 },
    ],
    built_in: "Medium Printer",
    byte_cost: 2000,
  },
  large_storage_silo_a: {
    name: "Large Storage Silo A",
    tier: "large",
    resources: [
      { res: resources.aluminum, amount: 2 },
      { res: resources.steel, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 5000,
  },
  large_storage_silo_b: {
    name: "Large Storage Silo B",
    tier: "large",
    resources: [
      { res: resources.steel, amount: 3 },
    ],
    built_in: "Medium Printer",
    byte_cost: 7500,
  },
  buggy: {
    name: "Buggy",
    tier: "large",
    resources: [
      { res: base.compound, amount: 1 },
      { res: resources.aluminum, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 1500,
  },
  large_rover_seat: {
    name: "Large Rover Seat",
    tier: "large",
    resources: [
      { res: resources.plastic, amount: 2 },
      { res: base.compound, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 2000,
  },
  medium_rover: {
    name: "Medium Rover",
    tier: "large",
    resources: [
      { res: resources.plastic, amount: 2 },
      { res: resources.rubber, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 3750,
  },
  crane: {
    name: "Crane",
    tier: "large",
    resources: [
      { res: resources.steel, amount: 1 },
      { res: resources.silicone, amount: 1 },
      { res: resources.titanium, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 4500,
  },
  large_fog_horn: {
    name: "Large Fog Horn",
    tier: "large",
    resources: [
      { res: resources.plastic, amount: 1 },
      { res: resources.rubber, amount: 1 },
      { res: resources.steel, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 4000,
  },
  vtol: {
    name: "VTOL",
    tier: "large",
    resources: [
      { res: resources.tungsten_carbide, amount: 1 },
      { res: resources.silicone, amount: 1 },
      { res: resources.exo_chip, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: "Unlocked by Missions",
  },
  rail_engines: {
    name: "Rail Engines",
    tier: "medium",
    resources: [
      { res: base.resin, amount: 1 },
      { res: resources.aluminum, amount: 1 },
      { res: resources.copper, amount: 1 },
    ],
    byte_cost: 1000,
    power_draw: "3 P/s",
  },
  rail_cars: {
    name: "Rail Cars",
    tier: "medium",
    resources: [
      { res: base.resin, amount: 2 },
      { res: resources.aluminum, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 1500,
    power_draw: "1 P/s"
  },
  recreational_sphere: {
    name: "Recreational Sphere",
    tier: "large",
    resources: [
      { res: resources.aluminum_alloy, amount: 1 },
      { res: resources.rubber, amount: 1 },
    ],
    built_in: "Medium Printer",
    byte_cost: 4500,
  },

  // extra large objects - tier 4
  shelter: {
    name: "Shelter",
    tier: "extra large",
    resources: [
      { res: resources.plastic, amount: 2 },
      { res: resources.silicone, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: "Unlocked",
  },
  auto_extractor: {
    name: "Auto Extractor",
    tier: "extra large",
    resources: [
      { res: resources.tungsten_carbide, amount: 1 },
      { res: resources.rubber, amount: 1 },
      { res: resources.steel, amount: 1 },
      { res: resources.exo_chip, amount: 1 },
    ],
    built_in: "Large Printer",
    byte_cost: 7500,
  },
  extra_large_shredder: {
    name: "Extra Large Shredder",
    tier: "extra large",
    resources: [
      { res: resources.tungsten_carbide, amount: 1 },
      { res: resources.steel, amount: 1 },
      { res: resources.exo_chip, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 5000,
  },
  solar_array: {
    name: "Solar Array",
    tier: "extra large",
    resources: [
      { res: resources.copper, amount: 1 },
      { res: resources.glass, amount: 1 },
      { res: resources.graphene, amount: 1 },
      { res: resources.aluminum_alloy, amount: 1 },
    ],
    built_in: "Large Printer",
    byte_cost: 6000,
  },
  xl_wind_turbine: {
    name: "XL Wind Turbine",
    tier: "extra large",
    resources: [
      { res: resources.iron, amount: 1 },
      { res: resources.ceramic, amount: 1 },
      { res: resources.graphene, amount: 1 },
      { res: resources.aluminum_alloy, amount: 1 },
    ],
    built_in: "Large Printer",
    byte_cost: 4500,
  },
  medium_sensor_arch: {
    name: "Medium Sensor Arch",
    tier: "extra large",
    resources: [
      { res: resources.zinc, amount: 1 },
      { res: base.quartz, amount: 1 },
    ],
    built_in: "Large Printer",
    byte_cost: 500,
  },
  xl_sensor_arch: {
    name: "XL Sensor Arch",
    tier: "extra large",
    resources: [
      { res: resources.zinc, amount: 2 },
      { res: base.quartz, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 500,
  },
  xl_sensor_canopy: {
    name: "XL Sensor Canopy",
    tier: "extra large",
    resources: [
      { res: resources.zinc, amount: 2 },
      { res: base.quartz, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 1000,
  },
  large_sensor_ring: {
    name: "Large Sensor Ring",
    tier: "extra large",
    resources: [
      { res: resources.zinc, amount: 1 },
      { res: base.quartz, amount: 1 },
    ],
    built_in: "Large Printer",
    byte_cost: 500,
  },
  large_sensor_hoop_a: {
    name: "Large Sensor Hoop A",
    tier: "extra large",
    resources: [
      { res: resources.zinc, amount: 1 },
      { res: base.quartz, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 750,
  },
  large_sensor_hoop_b: {
    name: "Large Sensor Hoop B",
    tier: "extra large",
    resources: [
      { res: resources.zinc, amount: 2 },
      { res: base.quartz, amount: 1 },
    ],
    built_in: "Large Printer",
    byte_cost: 750,
  },
  xl_sensor_hoop_a: {
    name: "XL Sensor Hoop A",
    tier: "extra large",
    resources: [
      { res: resources.zinc, amount: 2 },
      { res: base.quartz, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 750,
  },
  xl_sensor_hoop_b: {
    name: "XL Sensor Hoop B",
    tier: "extra large",
    resources: [
      { res: resources.zinc, amount: 3 },
      { res: base.quartz, amount: 1 },
    ],
    built_in: "Large Printer",
    byte_cost: 1000,
  },
  extra_large_platform_a: {
    name: "Extra Large Platform A",
    tier: "extra large",
    resources: [
      { res: resources.iron, amount: 2 },
      { res: resources.ceramic, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 2000,
  },
  extra_large_platform_b: {
    name: "Extra Large Platform B",
    tier: "extra large",
    resources: [
      { res: resources.iron, amount: 4 },
    ],
    built_in: "Large Printer",
    byte_cost: 3000,
  },
  extra_large_platform_c: {
    name: "Extra Large Platform C",
    tier: "extra large",
    resources: [
      { res: base.resin, amount: 2 },
      { res: resources.iron, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 2000,
  },
  extra_large_curved_platform: {
    name: "Extra Large Curved Platform",
    tier: "extra large",
    resources: [
      { res: resources.ceramic, amount: 2 },
      { res: base.compound, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 2000,
  },
  xl_extended_platform: {
    name: "XL Extended Platform",
    tier: "extra large",
    resources: [
      { res: base.resin, amount: 3 },
    ],
    built_in: "Large Printer",
    byte_cost: 750,
  },
  figurine_platform: {
    name: "Figurine Platform",
    tier: "extra large",
    resources: [
      { res: resources.iron, amount: 4 },
    ],
    built_in: "Large Printer",
    byte_cost: 3000,
  },
  extra_large_storage: {
    name: "Extra Large Storage",
    tier: "extra large",
    resources: [
      { res: resources.iron, amount: 2 },
      { res: resources.ceramic, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 2000,
  },
  large_rover: {
    name: "Large Rover",
    tier: "extra large",
    resources: [
      { res: resources.aluminum_alloy, amount: 1 },
      { res: resources.rubber, amount: 1 },
      { res: resources.exo_chip, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 5000,
  },
  landing_pad: {
    name: "Landing Pad",
    tier: "extra large",
    resources: [
      { res: resources.aluminum, amount: 1 },
      { res: resources.ceramic, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 750,
  },
  small_shuttle: {
    name: "Small Shuttle",
    tier: "extra large",
    resources: [
      { res: resources.aluminum, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 1500,
  },
  medium_shuttle: {
    name: "Medium Shuttle",
    tier: "extra large",
    resources: [
      { res: resources.aluminum_alloy, amount: 1 },
      { res: resources.ceramic, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 3750,
  },
  large_shuttle: {
    name: "Large Shuttle",
    tier: "extra large",
    resources: [
      { res: resources.titanium_alloy, amount: 1 },
      { res: resources.ceramic, amount: 1 },
      { res: resources.exo_chip, amount: 2 },
    ],
    built_in: "Large Printer",
    byte_cost: 5000,
  },
  rail_station: {
    name: "Rail Station",
    tier: "extra large",
    resources: [
      { res: resources.titanium, amount: 2 },
      { res: resources.copper, amount: 1 },
      { res: resources.quartz, amount: 1 },
    ],
    built_in: "Large Printer",
    byte_cost: 2500,
  },

  // resources
  ...resources,
}
