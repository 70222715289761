<template>
  <div><h5>Tree:</h5></div>
  <div id="tree" class="tree"></div>
</template>

<script>
import * as Treeviz from 'treeviz'
import base from '../api/base'

export default {
  
  props: {
    input: Object,
  },

  data() {
    return {
      myTree: null,
    }
  },

  computed: {
    resourceTree() {
      return this.createResourceTree(this.input) 
    }
  },

  methods: {
    createResourceTree( resource ) {
      const data = { ...resource }
      
      if (data.resources) {
        const simplifiedResources = []
        for (const resource of data.resources) {
          const res = this.createResourceTree(resource.res)
          res.amount = resource.amount
          simplifiedResources.push(res)
        }
        data.resources = simplifiedResources
      } else if (data.sources) {
        if (base.allPlanets.every(p => data.sources.includes(p))) {
          data.sources = data.sources.filter(e => base.allPlanets.indexOf(e) === -1)
          data.sources.unshift("All Planets")
        }
      }
      return data
    }
  },

  watch: {
    resourceTree(newTree) {
      this.myTree.refresh(newTree)
    }
  },

  mounted() {
      this.myTree = Treeviz.create({
        htmlId: "tree",
        idKey: "name",
        hasFlatData: false,
        relationnalField: "resources",
        isHorizontal: true,
        mainAxisNodeSpacing: 1.25,
        secondaryAxisNodeSpacing: 1.25,
        linkShape: 'quadraticBeziers',
        hasZoom: false,
        hasPan: false,
        areaWidth: 700,
        areaHeight: 900,
        nodeWidth: 200,
        linkColor: function() {
          return "#dddddd"
        },
        renderNode: function(node) {
          const amount = node.data.amount ? node.data.amount + 'x ': ''
          const built_in = node.data.built_in ? node.data.built_in : ''
          let sources = node.data.sources ? 'Sources: ' + node.data.sources : ''
          const byte_cost = node.data.byte_cost ? 'Byte Cost: ' + node.data.byte_cost : ''
          
          return `<div class='box'>
                    <div class='title'>${amount}${node.data.name}</div>
                    <div class='sub'>${built_in}</div>
                    <div class='sub'>${byte_cost}</div>
                    <div class='sub sources'>${sources}</div>
                  </div>
          `
        }
      })
    }
}
</script>

<style lang="scss">
.tree {
  height: 800px;
  width: 100%;
  border: 2px solid #2d9bf0;
  border-radius: 5px;
}

.box {
  width: 200px;
  height: 100px;
  border: 2px solid #2d9bf0;
  border-radius: 5px;
  padding: 2px;
  

  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
}

.box .title {
  color: #ffcf01;  
  background-color: #2d9bf0;
  border-radius: 5px;
  font-size: 1.10rem;
  font-weight: bold;
  padding: 0 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box .sub {
  font-size: 0.85rem;
}
</style>