<template>
  <div class="container-lg mt-2">
    <h1 class="heading text-center">Astroneer Resource Tree</h1>
    <search-bar @searched="searchPerformed" :objects="objects"></search-bar>

    <div class="mb-3">
      <h5>Summary:</h5>
      <required-base-resources :selectedObject="selectedObject"></required-base-resources>
      <optimal-planets :selectedObject="selectedObject"></optimal-planets>
    </div>
    
    <div class="alert alert-danger" v-if="searchError">
      Eintrag noch nicht vorhanden! {{ searchError }}
    </div>
    
    <resource-tree :input="selectedObject"></resource-tree>
  </div>
</template>

<script>
import SearchBar from './components/SearchBar'
import ResourceTree from './components/ResourceTree'
import RequiredBaseResources from './components/RequiredBaseResources'
import OptimalPlanets from './components/OptimalPlanets'

import objects from './api/objects'

export default {
  name: 'App',

  components: {
    SearchBar,
    ResourceTree,
    RequiredBaseResources,
    OptimalPlanets
},

  data() {
    return {
      objects: objects,
      searchError: '',
      selectedObject: {},
    }
  },

  methods: {
    searchPerformed( searchedEntity ) {   
      const entity = objects[searchedEntity];
      if (entity === undefined) {
        this.searchError = searchedEntity
        return;
      }

      this.selectedObject = entity
    },
  }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "vue-select/src/scss/vue-select.scss";

.heading {
  color: #ffcf01;  
  background-color: #2d9bf0;
  border-radius: 5px;
  padding: 5px 0
}

</style>>
