"use strict";

// cudos to https://observablehq.com/@sgregson/astroneer-resource-planner
const allPlanets = [ "Sylva", "Desolo", "Calidor", "Vesania", "Movus", "Glacio", "Atrox"]

const smelting = ({ built_in: "Smelting Furnance" })
const condenser = ({ built_in: "Atmospheric Condenser" })
const lab = ({ built_in: "Chemistry Lab" })
const trade = ['Trade Platform']

// base resources
const resin = { name: "Resin",  sources: [...allPlanets, ...trade] }
const quartz = { name: "Quartz",  sources: [...allPlanets, ...trade] }
const compound = { name: "Compound",  sources: [...allPlanets, ...trade] }
const organic = { name: "Organic",  sources: [...allPlanets, ...trade] }
const wolframite = { name: "Wolframite",  sources: ['Desolo', 'Calidor'] }
const clay = { name: "Clay",  sources: [...allPlanets, ...trade] }
const hematite = { name: "Hematite",  sources: ['Novus', 'Glacio'] }
const malachite = { name: "Malachite",  sources: ['Calidor', 'Sylva'] }
const laterite = { name: "Laterite",  sources: [...allPlanets, ...trade] }
const ammonium = { name: "Ammonium",  sources: [...allPlanets, ...trade] }
const graphite = { name: "Graphite",  sources: [...allPlanets, ...trade] }
const titanite = { name: "Titanite",  sources: ['Glacio', 'Vesania'] }
const lithium = { name: "Lithium",  sources: ['Vesania', 'Novus'] }
const astronium = { name: "Astronium",  sources: [...allPlanets] }
const sphalerite = { name: "Sphalerite",  sources: ['Sylva', 'Desolo'] }

//condenser
const methane = { name: "Methane",  ...condenser, sources: ['Novus', 'Glacio'] }
const sulfur = { name: "Sulfur",  ...condenser, sources: ['Calidor', 'Atrox'] }
const argon = { name: "Argon",  ...condenser, sources: ['Vesania', 'Atrox'] }
const hydrogen = { name: "Hydrogen",  ...condenser, sources: ['Sylva', 'Calidor', 'Vesania', 'Novus'] }
const nitrogen = { name: "Nitrogen",  ...condenser, sources: ['Sylva', 'Vesania', 'Atrox'] }
const helium = { name: "Helium",  ...condenser, sources: ['Atrox'] }

export default {
  allPlanets,
  
  resin,
  quartz,
  compound,
  organic,
  wolframite,
  clay,
  hematite,
  malachite,
  laterite,
  ammonium,
  graphite,
  titanite,
  lithium,
  astronium,
  sphalerite,

  //condenser
  methane,
  sulfur,
  argon,
  hydrogen,
  nitrogen,
  helium,

  smelting,
  condenser,
  lab,
  trade
}